import React from 'react'
import './header.css'
import CTA from './CTA'
import me from './../../assets/Shanda-4-removebg-preview.png';
import HeaderSocials from './HeaderSocials'

const Header = () => {
  return (
   <header>
      <div className="container header__container">
        <h5>Hello I'm</h5>
        <h1>Shanda Pinell</h1>
        <h5 className="bg-light">Fullstack Developer</h5>
        <CTA></CTA>
        <HeaderSocials></HeaderSocials>

        <div className="me">
          <img src={me} alt="" />
        </div>

        <a href="#contact" className="scroll_down">Scroll Down</a>
      </div>
   </header>
  )
}

export default Header